@import '../../styles/variables.pcss';

.search-trigger {
  font-weight: normal;
  position: relative;
  cursor: pointer;
  color: $th-lightest-gray;

  &__input-placeholder {
    display: none;
    color: $th-gray;
  }

  @media only screen and (min-width: $search-input-breakpoint) {
    font-weight: normal;
    width: 100%;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid $th-lightest-gray;
    border-radius: 15px;

    button {
      margin: 0; /* Normalize input height on Safari */
      font-size: 14px;
      padding: 6px 15px 6px 40px;
    }

    &__input-placeholder {
      display: block;
      color: $th-gray;
    }

    .search-icon {
      position: absolute;
      left: 15px;
      top: 9px;
      cursor: pointer;
      color: $th-gray;
    }
  }

  &__icon {
    font-size: 20px;
    color: $th-lightest-gray;

    @media only screen and (min-width: $breakpoint-sm) {
      font-size: 25px;
    }

    @media only screen and (min-width: $search-input-breakpoint) {
      display: none;
    }
  }
}
