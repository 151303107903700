@import '../../styles/variables.pcss';

.item-details {
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
  overflow: hidden;

  &__detail {
    font-size: 12px;
    line-height: 20px;

    &--shrink {
      flex-shrink: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &_time {
      color: $th-news-time;
      margin-right: 15px;
    }

    &_source {
      color: $th-news-source;
    }

    &_category {
      margin-right: 15px;
      &, &:visited {
        color: $th-news-category;
      }
      &:hover {
        color: $th-visited-font-color;
      }
    }

    &_clicks {
      display: inline-flex;
      color: $th-news-clicks;

      > svg {
        margin-right: 2px;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.item-details__detail_category::before {
  content: '|';
  margin: 0 5px;
  color: $th-news-category;
}
