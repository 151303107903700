.more-items {
  font-size: 18px;

  .spin {
    font-size: 20px;
  }

  &-button {
    background: $th-news-item-bg;
    width: 100%;
    padding: 25px 0;
  }
}
