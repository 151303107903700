@import '../../styles/variables.pcss';

body.lw-normal .search-bar {
  max-width: $content-width;
}

body.lw-wide .search-bar {
  max-width: $content-width-wide;
}

body.lw-full .search-bar {
  max-width: $content-width-full;
}

.search-bar {
  width: 100%;
  z-index: 1;
  box-shadow: 0 2px 12px -4px #AAAAAA;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  position: fixed;
  top: $menu-height;
  display: flex;
  justify-content: center;
  align-items: center;

  &-close {
    font-size: 30px;
    color: $th-gray;
    margin: 0 20px 0 10px;
  }

  &.opened {
    max-height: 100px;
    opacity: 1;
    transition: opacity 0.25s ease-out, top 0.25s ease-out;
    height: auto;
    overflow: visible;
    z-index: 21;
  }

  .search-text {
    color: $th-font-color;
    font-weight: bold;
    width: 240px;
    flex: none;
    font-size: 15px;
    align-self: center;
  }

  .search-input-container {
    display: flex;
    height: 60px;
    width: 100%;

    .search-input {
      width: 100%;
    }

    input {
      border: 0;
      width: 100%;
      box-shadow: none;
      outline: none;
    }
  }
}

.search-bar-container {

  &.search-page {
    min-height: calc($menu-height + 10px);
  }
}
