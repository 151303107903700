@import 'variables.pcss';

.not-found-container {
  display: flex;
  padding: calc($gutter * 3) $gutter;
  flex-wrap: wrap;
  justify-content: center;

  .not-found-icon {
    font-size: 100px;
    padding: 0 40px 20px;
    text-align: center;
    width: 100%;
    color: $th-not-found-icon;
  }

  h1 {
    font-size: 38px;
    margin: 0 0 $gutter;
  }

  p {
    line-height: 170%;
    font-size: 16px;
    max-width: 520px;
    margin: 0;
  }
}

@media only screen and (min-width: 500px) {

  .not-found-container {
    flex-wrap: nowrap;

    .not-found-icon {
      width: auto;
    }
  }
}

@media only screen and (min-width: $breakpoint-sm) {

  .not-found-container {

    .not-found-icon {
      font-size: 150px;
      padding: 0 80px;
    }
  }
}
