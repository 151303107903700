@import 'variables.pcss';

.sidebar {
  max-width: 300px;

  > * + * {
    margin-top: $gutter;
  }
}


.sidebox {
  .sidebox-header {
    background-color: $th-main-color;
    color: $th-sidebar-box-header;
    margin: 0;
    padding: 10px;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 20px;

    a {
      color: $th-sidebar-box-header;
      display: flex;
      align-items: center;
      overflow: hidden;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .fa-circle-thin {
      font-size: 1.3em;
    }
    .fa-angle-right {
      font-size: 0.8em;
    }
  }

  .sidebox-content {
    padding: 10px 0;
    background-color: $th-sidebar-box-background;
    font-size: 13px;
  }
}
