@import 'variables.pcss';

.login-overlay {
  position: relative;
  z-index: 26;

  &__outer-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $th-login-overlay-background-color;
    z-index: 23;
    top: 0;

    @media only screen and (min-width: $breakpoint-md) {
      background-color: rgba(255, 255, 255, 0.91);
    }
  }

  &__inner-wrapper {
    height: 100%;
    overflow-y: scroll;

    @media only screen and (min-width: $breakpoint-md) {
      margin-top: 70px;
    }
  }

  &__content {
    margin: 50px auto 0;
    max-width: 300px;
    padding-bottom: 70px;

    @media only screen and (min-width: $breakpoint-md) {
      margin-top: 0;
      padding-bottom: 140px; /* extra 70px to counter the top margin of .login-overlay-content */
    }
  }

  &__widget {
    + .login-overlay__widget {
      margin-top: 40px;
    }
  }

  .button .loading {
    background-color: unset;
    padding: 1px;
  }
}

.close-login-overlay {
  top: 15px;
  right: 30px;
  position: absolute;
  font-size: 35px;
  color: $th-font-color;
  cursor: pointer;
  display: flex;

  .close-text {
    font-size: 16px;
    line-height: 35px;
  }

  @media only screen and (min-width: $breakpoint-md) {
    top: 70px;
    right: 70px;
    font-size: 50px;

    .close-text {
      line-height: 50px;
    }
  }
}

.selected-profiles {
  display: flex;
  flex-direction: column;
}

.user-login-info {
  &-text {
    margin: 10px 0;
    white-space: nowrap;
  }

  &-email {
    font-weight: bold;
  }

  .button {
    width: 100%;
  }
}

.error {
  color: $error-color;
}

@media only screen and (min-width: $breakpoint-md) {
  _:-ms-fullscreen, :root .menu-profile {
    background-color: rgba(255, 255, 255, 1);
  }
}
