@import '../../styles/variables.pcss';

.source-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: $th-news-header;
  line-height: 25px;
  position: relative;

  &:hover {
    background: $th-settings-highlight;
  }

  &__link {
    min-width: fit-content;
    color: $th-news-header;

    &-blacklisted {
      color: $th-grayish-color;
    }
  }

  &__status {
    display: flex;
    color: $th-font-color;
    flex-direction: row;
    width: max-content;
    margin-right: 10px;
    align-items: center;
    position: absolute;
    top: 0;
    right: 10px;

    &-blacklisted {
      color: $error-color!important;
    }

    &-paywalled {
      width: inherit;

      .fa-stack {
        color: #005387;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: inherit;
        width: 0;
        padding: 0 10px;
      }

      .fa-circle {
        font-size: 1.5em;
      }

      .fa-stack-1x {
        right: 1px;
        top: 0px;
      }

      &_text {
        display: none;

        @media only screen and (min-width: $breakpoint-sm) {
          display: block;
        }
      }
    }

    &-subscribed {

      &:before {
        font-family: 'FontAwesome';
        content: '\f153';
      }

      @media only screen and (min-width: $breakpoint-sm) {
        display: block;
      }
    }
  }

  &__toggle {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (pointer: coarse) {
      padding: 10px 0;
    }
  }

  &__menu-container {
    position: absolute;
    right: 0;
    top: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border: 1px solid $th-highlight-color;
    background-color: $th-news-list-background;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;

    &-button {
      display: flex;
      width: 100%;
      padding: 4px;

      &:hover {
        background: $th-highlight-color-hover;
        color: #FFFFFF;
      }

      > span {
        width: fit-content;
      }
    }
  }

  .error {
    cursor: not-allowed;
    color: $error-color;
  }
}


