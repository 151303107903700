@import 'variables.pcss';

/* Styles for the tag/category switch used both in sidebar and item list */
.category-switch {
  display: inline-flex;
  justify-content: center;
  margin: 2.5px;
  white-space: nowrap;
  font-size: 13px;
  border: 1px solid;
  border-color: $highlight-blue;
  border-radius: 5px;
  width: fit-content;

  > * {
    vertical-align: top;
  }

  &__label, &__label:visited {
    color: $th-category-switch-text-color;
  }

  &__label {
    display: inline-block;
    color: $highlight-blue;
    padding: 0 2px 0 6px;
    font-weight: 600;
    height: 24px;
    line-height: 24px;
  }

  &_whitelisted {
    .category-switch,
    .category-switch__label,
    .category-switch__label:visited,
    .category-switch__label:hover {
      background: $highlight-blue;
      color: $th-whitelisted-category-switch-text-color;
      height: 100%;
    }
  }

  &_blacklisted {
    border-color: $th-dark-gray;

    .category-switch,
    .category-switch__label,
    .category-switch__label:visited,
    .category-switch__label:hover {
      color: $th-dark-gray;
      opacity: 0.6;
    }
  }

  &__menu-container {
    position: absolute;
    padding: 4px;
    margin-top: 20px;
    border: 1px solid $highlight-blue;
    background-color: #FFFFFF;
    overflow: hidden;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
}

.switch-toggle {
  display: inline-block;
  color: $highlight-blue;
  padding: 0 12px;

  &_whitelisted {
    background: $highlight-blue;
    color: $th-whitelisted-category-switch-text-color;
  }

  &_blacklisted {
    opacity: 0.4;
    color: $th-blacklisted-widget-icon-color;
  }
}

.whitelist-toggle,
.blacklist-toggle {
  display: block;
  color: #777777;
  margin: 1px 3px 1px 0;
  padding: 1px;

  @media (pointer: coarse) {
    padding: 4px;
  }


  &_active {
    color: $highlight-blue;
  }
}

.blacklist-toggle {
  margin-top: 4px;
}
