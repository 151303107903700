@import '../../styles/variables.pcss';

.saved-search {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  border-radius: 5px;
  font-size: 13px;
  margin: 0 -2.5px; /* For mobile menu set negative margin */

  @media only screen and (min-width: $mobile-menu-breakpoint) {
    margin: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    border: 1px solid $highlight-blue;
    border-radius: 5px;
    margin: 2.5px;
    padding: 0 6px;

    &-link {
      text-decoration: none;
      color: $highlight-blue;
      margin-right: 5px;
      font-weight: 600;
      height: 24px;
      line-height: 24px;

      &:hover {
        opacity: 0.8;
      }
    }

    &-remove {
      cursor: pointer;
      color: #999999;

      &:hover {
        color: $th-red-darker-color;
      }
    }
  }

  &__empty {
    margin: 5px;
  }

  &__not-logged-in {
    margin: 5px;
    color: #999999;
    text-align: left;

    &:hover {
      color: $th-highlight-color;
    }
  }
}
