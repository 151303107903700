@import '../../styles/variables.pcss';

.notification-bubble {
  background: $th-news-list-background;
  color: $th-font-color;
  font-size: 14px;
  padding: 0 5px;
  position: absolute;
  bottom: 35px;
  left: 25px;
  transform: translateX(-50%);
  text-align: center;
  border-radius: 5px;
  box-shadow: 2px 2px 15px 15px $th-notification-shadow;

  &:hover {
    box-shadow: 2px 2px 15px 15px $th-notification-shadow-hover;
  }
}

.notification-bubble:after {
  border: 10px solid transparent;
  border-top-color: $th-news-list-background;
  content: '';
  position: absolute;
  top: 100%;
  left: 45px;
}
