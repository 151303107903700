@import '../../styles/variables.pcss';

.mobile-submenu {
  position: fixed;
  top: $menu-height;
  bottom: $menu-height;
  left: 0;
  width: 100%;
  background-color: $th-mobile-menu-background-color;
  height: 100%;
  overflow-y: auto;
  z-index: 3;

  &-items {
    margin: 0;
  }

  /* Categories  */

  .vertical {
    width: 100%;
    height: auto;
    margin: 5px 0;
    padding: 0 0 120px;
    display: flex;
    flex-direction: column;

    .menu-section {
      border-radius: 6px;
      padding: 10px 0;
      margin: 0 10px 7px;
      background: $th-news-list-background;

      &-title {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        margin: 0 10px;
      }

      &-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 10px 5px;
        margin: 0 10px;

        &__item {
          border-radius: 10px;
          padding: 10px;
          width: 40px;
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #FFFFFF;

          &-text {
            font-size: 10px;
            font-weight: bold;
          }

          &.tv {
            background-color: #47A2BF;
          }

          &.weather {
            background-color: #F7CA45;
          }

          &.sport {
            background-color: #7BB837;
          }

          &.tag {
            background-color: #005588;
          }

          &.feedback {
            background-color: #B8A089;
          }
        }
      }
    }

    .user-login-info {
      display: flex;
      justify-content: center;
      margin: 0 15px;

      .button {
        width: 50%;
        margin-top: 0;
      }
    }

    .user-logout {
      display: flex;
      justify-content: center;

      .button {
        width: 50%;
        margin-top: 0;
      }
    }

    .mobile-menu-item {
      margin: 0 5px;
      padding: 15px 0;
      flex-direction: column;
      list-style: none;

      &:not(.search-input-container):not(:last-child) {
        border-bottom: 1px solid $th-border-color-dark;
      }

      &-row {
        width: 100%;
        display: flex;

        .arrow-toggle__button {
          padding: 0 30px;
        }

        &__link {
          width: 100%;
          color: $th-font-color;
          display: flex;

          &--blacklisted {
            color: $th-light-gray;
          }

          &--active {
            color: $th-nav-active-tab-text-color;
          }

          &-text {
            margin-right: 10px;
            font-weight: bold;
          }
        }
      }

      .subpage-vertical {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        background-color: $th-news-list-background;
        position: inherit;
        top: -1px; /* To hide border from menu above */
        overflow-y: auto;
        width: 100%;
      }
    }
  }

  .quickfilter-mobile {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 5px;
    padding: 10px;
    color: $th-font-color;
    font-size: 14px;
    font-weight: bold;

    .Dropdown-root {
      position: absolute;
      top: -8px;
      border-radius: 10px;
      width: 70%;

      &.logged-in {

        .Dropdown-option:nth-child(4) {
          /* important because otherwise theme overrides it */
          /* stylelint-disable declaration-no-important */
          border-bottom: none!important;
          /* stylelint-enable declaration-no-important */
          padding-bottom: 0;
        }
      }

      > * {
        padding: 8px;
      }

      .Dropdown-menu {
        margin: 5px 0 0;
        color: $th-font-color;
        background: $th-news-list-background;
      }

      .Dropdown-option:not(:last-child) {
        padding-bottom: 10px;
        border-bottom: 1px solid $th-dropdown-border-color;
      }

      .icon:before {
        font-family: 'FontAwesome';
        display: inline-block;
        margin: 5px 5px 0 0;
        font-weight: lighter;
        text-align: center;
        width: 14px;
      }

      .news:before {
        content: '\f1ea';
      }

      .entertainment:before {
        content: '\f006';
      }

      .sports:before {
        content: '\f1e3';
      }

      .login {
        font-size: 10px;
        color: $th-font-color;
        text-decoration: underline;
        margin: 15px 0 5px;
      }
    }
  }
}
