@import '../../styles/variables.pcss';

.item-actions-container {
  padding: 0 15px 5px 20px;
  margin-top: -5px;
  min-height: 32px;
}

.item-actions-category-container {
  flex-wrap: wrap;

  .custom-feed-info {
    color: $th-light-font-color;
  }
}

.item-actions-top-container {
  display: flex;
  flex-basis: 100%;

  &-left {
    flex: 1;
  }

  &-right {
    display: flex;
    align-items: center;
    color: $th-news-clicks;
    fill: $th-news-clicks;
    gap: 5px;
  }
}

.source-blacklist-button {
  cursor: pointer;
  color: gray;
  font-size: 12px;

  &:hover {
    color: $th-light-font-color;
  }

  &-blacklisted {
    color: red;
    font-size: 12px;
  }
}

