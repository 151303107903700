@import '../../styles/variables.pcss';

.logo-wrapper {

  .brand-logo {

    object, img {
      display: block;
      height: 18px;
      width: auto;
    }

    @media only screen and (min-width: 587px) {

      object, img {
        height: 25px;
        max-width: 150px;
      }
    }
  }
}
