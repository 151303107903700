@import 'variables.pcss';

.more-content {
  text-align: center;
  background: $th-highlight-color;
  color: #FFFFFF;
  padding: calc($gutter * 2) $gutter calc($gutter * 3) $gutter;

  &__title {
    font-size: 24px;
    margin: 0 0 $gutter;
  }

  .more-content__links {
    /* needs to be hierarchical, otherwise themes will override colors */
    margin-bottom: 10px;

    a {
      margin: 10px;
      color: #FFFFFF;
      border: 1px solid #FFFFFF;
      padding: 15px 20px;
      font-size: 16px;
      border-radius: 30px;
      display: inline-block;

      &:visited {
        color: #FFFFFF;
      }

      &:hover, &:active {
        background: #FFFFFF;
        color: $th-highlight-color;
      }

      @media only screen and (min-width: $breakpoint-sm) {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }
}
