@import '../../styles/variables.pcss';

.mobile-bottom-nav-container {
  position: fixed;
  display: flex;
  border-top: 2px solid $th-bottom-nav-highlight-color;
  bottom: 0;
  width: 100%;
  height: $menu-height;
  z-index: 1000;

  @media (min-width: $mobile-menu-breakpoint) {
    display: none;
  }

  &--hidden {
    transform: translateY(100%);
    transition: transform 0.2s ease-out;
  }

  &--visible {
    transform: translateY(0);
    transition: transform 0.2s ease-in;
  }
}

.mobile-bottom-nav {
  background-color: $th-news-list-background;
  display: flex;
  width: 100%;
  justify-content: space-around;
  gap: 20px;
  align-items: center;
  margin: 0;
  list-style: none;
  padding: 0;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;

    &__icon {
      color: $highlight-color;
      font-size: 24px;
      margin-bottom: 2px;
    }

    &__text {
      font-size: 9px;
      color: $th-font-color;
      font-weight: bold;
      text-overflow: ellipsis;
    }

    &--active {

      .mobile-bottom-nav-item__icon {
        color: $th-bottom-nav-highlight-color;
      }
    }
  }
}

.menu-toggle-container {
  padding: 0 5px;
}

.menu-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  padding: 0 15px;

  &__bars {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 19px;
  }

  &__bar {
    position: absolute;
    width: 100%;
    height: 3px;
    opacity: 1;
    background-color: $highlight-color;
    transform-origin: left center;
    transition: 0.3s ease-in-out;

    &--top {
      top: -6px;
      transform-origin: left center;
    }

    &--middle {
      top: 2px;
      transform-origin: left center;
    }

    &--bottom {
      top: 10px;
      transform-origin: left center;
    }
  }

  &--active {

    .menu-toggle__bar {
      background-color: $th-bottom-nav-highlight-color;

      &--top {
        transform: rotate(45deg);
        top: -6px;
        left: 4px;
      }

      &--middle {
        opacity: 0;
        width: 0;
      }

      &--bottom {
        transform: rotate(-45deg);
        top: 15px;
        left: 4px;
      }
    }
  }
}
