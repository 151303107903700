

.items-list_category-content {
  background: $th-news-list-background;
  border-bottom: 1px solid $th-news-item-borders;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
}



.items-list {

  h3 {
    padding-left: $gutter;
    margin-top: 0;
    margin-bottom: $gutter;
    font-size: 18px;
  }
}

.items-list-empty {
  background-color: $th-news-list-background;
  padding: 10px 30px;

  .title {
    display: inline-block;
    margin-top: 20px;
    font-size: 30px;
    font-weight: bold;
  }

  .content {

    p {
      margin-top: 20px;
      margin-bottom: 20px;

      a {
        font-style: italic;
      }
    }
  }

  .list-empty-img {
    width: 100%;
    display: inline-block;
    text-align: right;
    padding: 20px 20px 30px;
    box-sizing: border-box;

    .desktop, .mobile {
      max-width: 100%;
    }
  }
}
