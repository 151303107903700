@import './variables.pcss';

h1 {
  margin-top: $gutter;
  margin-bottom: calc($gutter / 2);
  font-size: 32px;
}

h2 {
  margin-top: $gutter;
  margin-bottom: calc($gutter / 2);
  font-size: 20px;
}

h3 {
  margin-top: $gutter;
  margin-bottom: calc($gutter / 2);
  font-size: 18px;
}

p {
  margin-top: 0;
  margin-bottom: calc($gutter / 2);
}

ul {
  margin: $gutter 0;
  padding: 0 0 0 $gutter;

  ul {
    margin: 0;
  }
}

a, a:hover, a:visited, a:focus, a:active {
  text-decoration: none;
  color: $th-highlight-color;

  &.text-link {
    text-decoration: $th-text-link-decoration;
    color: $th-text-link-color;
  }
}

button {
  border: none;
  background: none;
  border-radius: 0;
  cursor: pointer;
  font: inherit;
  line-height: inherit;
  padding: 0;
  color: inherit;
  margin: 0;
  color: inherit;
}

input, textarea {
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
  border: 1px solid $th-border-color;
  background-color: #FAFAFA;

  &:focus {
    background-color: #FFFFFF;
    box-shadow: none;
  }
}

input::placeholder, textarea::placeholder {
  color: #777;
}
