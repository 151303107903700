@import '../../styles/variables.pcss';

.breaking-news-box {
  background: $th-news-list-background;
  padding-bottom: 10px;
  position: relative;
  color: white;
  margin-bottom: 10px;

  &-header {
    font-weight: 600;
    background: $th-red-darker-color;
    width: fit-content;
    padding: 6px 10px;
    border-radius: 0 0 10px;
    margin: 0 0 5px;

    &__title {
      color: #FFFFFF;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0 8px;
    height: 100%;
  }
}

.breaking-news {
  display: flex;
  flex-direction: row;
  margin: 0;
  gap: 5px;

  .simple-item {

    + .simple-item {
      margin: 0;
    }

    .item-details__detail_source {
      white-space: normal;
    }

    .item-details__detail_category, .item-details__detail_clicks {
      display: none;
    }
  }

  .item-details {
    flex-wrap: wrap;
  }
}
