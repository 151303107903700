@import '../../styles/variables.pcss';

.menu-container {
  display: none;
}

.menu-content {
  background-color: $th-category-tree-background;
  margin-bottom: $gutter;
}


.menu-list {
  padding: 0;
  margin: 0;

  &--show-non-blacklisted .menu-item--blacklisted {
    display: none;
  }

  &--show-only-whitelisted {
    .menu-item {
      display: none;
    }
    .menu-item--whitelisted {
      display: list-item;
    }
  }

  .arrow-toggle__button {
    padding: 0 10px;
    font-size: 15px;
    color: $th-font-color;
  }
}



.menu-item {
  font-size: 11px;
  text-decoration: none;
  list-style: none;

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__link {
    flex-grow: 1;
    font-weight: bold;
    padding: 6px 5px 6px 10px;
    line-height: 13px;
  }

  & & &__link {
    padding-left: 25px;
  }
}


.menu-item__row {
  color: $th-font-color;
  background-color: $th-category-tree-background;
}
.menu-item--blacklisted > .menu-item__row {
  color: $th-light-gray;
}
.menu-item--active > .menu-item__row,
.menu-item--active > .arrow-toggle__button {
  color: $th-category-tree-active-text-color;
  background-color: $th-main-color;
}
.menu-item__row:hover {
  background-color: $th-category-tree-link-hover;
}
.menu-item__link {
  &:link, &:visited, &:active, &:focus, &:hover {
    color: inherit !important; /* needs !important because otherwise theme colors override with their higher specificity */
  }
}



#namedays {
  margin-top: $gutter;

  .sidebox-header {
    display: flex;
    justify-content: space-between;
  }

  .sidebox-content {
    padding: 10px;
    font-weight: bold;
    color: $th-font-color;
  }
}

@media only screen and (min-width: $mobile-menu-breakpoint) {

  .menu-container {
    display: block;
    width: 160px;
    margin-right: 10px;
    flex: 0 0 160px;
  }
}


