@import '../../styles/variables.pcss';


.newslist-widgets {
  background: $th-news-list-background;
  position: relative;
  z-index: 1;
}


.related-categories {
  padding: 7px 17px;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 7px 5px;

  &:not(.borderless) {
    border-bottom: 1px solid $th-news-item-borders;
  }

  .category-switch {
    margin: 0;
  }
}

/* Breadcumb links mimic style of category switches. */
.breadcrumbs {
  padding: 3px 0;

  font-weight: 600;
  line-height: 15px;
  font-size: 13px;
  color: $th-breadcrumb-separator-color;

  a:link, a:visited {
    color: $th-breadcrumb-link-color;
  }
  .fa {
    font-size: 14px;
    margin: 0 1px 0 2px;
  }
}
