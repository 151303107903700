@import '../../styles/variables.pcss';

.widget-box {
  &__header {
    background-color: $th-main-color;
    color: $th-sidebar-box-header;
    margin: 0;
    padding: 10px;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 20px;

    a {
      color: $th-sidebar-box-header;
    }

    .fa-circle-thin {
      font-size: 1.3em;
    }

    .fa-angle-right {
      font-size: 0.8em;
    }
  }

  &__content {
    padding: 10px 5px;
    background-color: $th-sidebar-box-background;
    font-size: 12px;

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  + .widget-box {
    margin-top: $gutter;
  }
}
