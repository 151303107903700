@import 'variables.pcss';

.ReactModal__Body--open {
  overflow: hidden;
}

.reactModal {
  color: $th-font-color;
  position: relative;
  top: 0;
  bottom: 40px;
  margin: 50px 0;
  padding: 40px 50px 20px;
  max-width: 500px;
  overflow: auto;
  outline: none;
  background: $th-modal-bg-color;
  box-shadow: 0 0 20px 1px rgba(64, 64, 64, 1);
  line-height: 20px;
  -webkit-overflow-scrolling: touch;

  ul {
    margin: 15px 0 15px 10px;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 0;
    cursor: pointer;
    font-size: 30px;
    color: $th-modal-close-button;

    &:hover {
      color: $th-font-color;
      transition: 0.2s;
    }
  }

  h1.modal-title {
    font-size: 30px;
    line-height: 1em;
    margin-bottom: 25px;
  }

  .modal-content {
    margin-bottom: 25px;
  }

  ul.buttons {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    li {
      width: 100%;
      max-width: 250px;
    }
  }

  .button {
    margin: 8px 0;

    &.positive {
      background-color: $modal-ok-color;

      &:hover {
        background-color: $modal-ok-color;
      }
    }

    &.negative {
      background-color: $modal-cancel-color;

      &:hover {
        background-color: $modal-cancel-color;
      }
    }

    &:hover {
      text-decoration: underline;
    }

    &.no-uppercase {
      text-transform: none;
    }
  }

  .modal-footer {
    font-size: 13px;
    text-align: center;
    padding-top: 20px;

    a {
      color: $th-font-color;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}

.reactModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.333);
  z-index: 100;
  height: auto;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &.alignToTop {
    align-items: baseline;
  }
}


.redirectModal {

  .countdown {
    font-size: 30px;
    font-weight: 500;
    color: gray;
    width: 100%;
    text-align: center;
    display: inline-block;
    margin-bottom: 25px;

    span {
      margin-left: 7px;
    }
  }
}

.urlmodal {

  input {
    width: 100%;
  }
}

/* NewUserModal **/

.temp-profile {
  padding-top: 20px;
}

.temp-profile-info {
  padding: 5px 0;
}

.temp-profile-toggle-label {
  display: flex;
  align-items: center;
  margin: 5px 0;

  > span {
    padding: 5px 0;
    font-weight: bold;
  }
}
