@import './variables.pcss';

.night-mode-button {
  box-sizing: border-box;
  color: $th-quickfilter-color;
  background: none;
  text-align: left;
  font-weight: 700;
  font-size: 12px;
  text-transform: none;
  border-radius: 0;
  height: 100%;

  &__icon {
    font-size: 23px;
    vertical-align: middle;
  }

  &__text {
    vertical-align: middle;
    padding: 10px 6px;
  }
}

.mobile-submenu .night-mode-button {
  flex: 0 0 45px;
  text-align: center;
}

.menu-container .night-mode-button {
  width: 100%;
  padding: 8px 15px 7px;
  border-bottom: 1px solid $th-menubar-divider-color;
}
