@import '../../styles/variables.pcss';

.personal-items-instructions {
  padding-top: 20px !important; /* global styles are currently read after component specific styles, making `important` necessary here. */
  border-bottom: 1px solid $th-news-item-borders;

  &__heading {
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;

    @media only screen and (min-width: $breakpoint-sm) {
      font-size: 35px;
    }
  }

  &__text {
    width: 100%;
    font-size: 14px;

    @media only screen and (min-width: $breakpoint-sm) {
      font-size: 16px;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    margin: 0 calc(-1*($gutter/2));

    @media only screen and (min-width: $breakpoint-md) {
      flex-direction: row;
    }
  }

  &__column {
    flex-basis: 50%;
    margin-bottom: 10px;
    padding: 0 calc($gutter / 2);
  }
}
