@import '../../styles/variables.pcss';

.simple-item {
  font-size: 13px;
  padding: 0 10px;

  &:hover {
    background: $th-popular-link-hover;
  }

  &__title {
    overflow-wrap: break-word;
    font-weight: 600;
    margin: 0;
    a, a:hover {
      color: $th-font-color;
      display: inline-block;
    }
    a:visited {
      color: $th-visited-font-color;
    }
  }

  &__meta {
    display: flex;
  }

  + .simple-item {
    margin-top: 7px;
  }

  /* Detail presentation overrides */
  .item-details__detail_paywalled_text {
    display: none;
  }
  .item-details__detail_category,
  .item-details__detail_source {
    font-size: 12px;
  }
}
