@import '../../styles/variables.pcss';

.list-title {
  display: inline-block;
  margin: 0;
  padding: 7px 10px;
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  white-space: nowrap;
  color: $th-font-color;

  &--tag::before {
    content: '#';
    padding-right: 5px;
    font-style: italic;
    font-size: 17px;
    line-height: 19px;
    color: $highlight-blue;
    opacity: 0.8;
  }
}

@container (width < 470px) {
  .list-title__tag-trailer {
    display: none;
  }
}
