/** @todo Check and refactor styles */

@import 'variables.pcss';

.settings-tabs {
  position: relative;

  li {
    min-width: 115px;
  }

  .carousel-left {
    background: linear-gradient(to left, rgba(240,239,228,0) 0%, $th-nav-background-color 50%);
    justify-content: flex-start;

    & > span {
      margin-left: 10px;
    }
  }

  .carousel-right {
    background: linear-gradient(to right, rgba(240,239,228,0) 0%, $th-nav-background-color 50%);
    justify-content: flex-end;

    & > span {
      margin-right: 10px;
    }
  }
}

.profile-settings-info-text {
  padding: 10px 10px 20px;
}

.hide-on-sm {
  display: none;

  @media only screen and (min-width: $breakpoint-md) {
    display: block;
  }
}

.settings-input-error {
  color: $error-color;
}

.root-category-ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  .list-style {
    width: 100%;
    margin: 5px 5px 20px;

    @media only screen and (min-width: $breakpoint-sm) {
      width: 48%;
      margin: 0 10px 10px 0;
    }
  }
}

.category-collapsible {
  font-weight: 400;
  cursor: pointer;
  justify-content: space-between;
  border-bottom: solid 2px $th-highlight-color;

  .open-collapsibles {
    padding-top: 3px;
  }
}

.category-collapsible-open {
  display: block;
  margin: 0 10px;
}

.category-collapsible-closed {
  display: none;
}

.category-item-action-container {
  display: flex;
}

.category-item {
  display: flex;

  .whitelisted-category-icon {
    color: $th-white;
  }

  .unwhitelisted-category-icon {
    color: $highlight-blue;
  }

  .blacklisted-category-icon {
    color: $th-white;
  }

  .unblacklisted-category-icon {
    color: $th-font-color;
  }

  .category-title {
    padding: 3px 3px 3px 20px;
  }

  .category-root-title {
    font-weight: bolder;
  }
}

.category-heart-active {
  background-color: $highlight-blue;
}

.category-eye-active {
  background-color: $th-dark-gray;
}

.category-heart, .category-eye {
  cursor: pointer;
  padding: 5px 15px;
}

.category-settings-container {
  width: 100%;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 5px 0 10px 0;
  }
}

.box-header {
  background-color: $th-main-color;
  color: $th-sidebar-box-header;
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}

.personal {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  @media only screen and (min-width: $breakpoint-md) {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
  }



  h2 {
    width: 100%;
    font-size: 20px;
    margin: 0 10px 30px;
    text-align: center;

    @media only screen and (min-width: $breakpoint-sm) {
      font-size: 35px;
    }
  }

  p {
    width: 100%;
    font-size: 14px;

    span {
      margin: 0 5px;
    }

    @media only screen and (min-width: $breakpoint-sm) {
      font-size: 16px;
    }
  }

  .forgotten-password {
    display: inline-block;
  }
}


/* SOURCE SETTINGS */

.source-settings {

  &__toggle {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 5px 0;
    width: 250px;

    &-container {
      display: block;
      margin: 0 0 10px 10px;
    }
  }

  &__tabs {
    position: relative;
    background: $th-nav-background-color;
    list-style: none;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    flex: 1 0 auto;

    &__list-item {
      border-top: 1px solid $th-nav-background-color;
      border-right: 1px solid $th-nav-background-color;
      border-left: 1px solid $th-nav-background-color;
      padding: 10px;
      cursor: pointer;
    }

    .active {
      background: white;
      color: $highlight-blue;
    }

    .disabled {
      pointer-events: none;
    }

    .carousel-left {
      background: linear-gradient(to left, rgba(240,239,228,0) 0%, $th-nav-background-color 50%);
      justify-content: flex-start;

      & > span {
        margin-left: 10px;
      }
    }

    .carousel-right {
      background: linear-gradient(to right, rgba(240,239,228,0) 0%, $th-nav-background-color 50%);
      justify-content: flex-end;

      & > span {
        margin-right: 10px;
      }
    }
  }
}

.list-style {
  text-decoration: none;
  list-style: none;
  font-size: 12px;
  font-weight: 400;
}

.sources, .hidden-sources {
  width: 100%;
  margin: 0 10px 10px 0;
}

.sources__list-container {
  margin: 5px;
}

.sources__list {
  padding: 0;
}

.hidden-sources__list {
  padding: 10px;
}

.sources__list-label-container {
  width: 100%;
  border-bottom: 1px solid $th-highlight-color;
}

.sources__list-label {
  font-weight: 700;
}

.sources__labels-container {
  width: 100%;
  min-height: 40px;
  position: sticky;
  top: 50px;
  background: $th-news-list-background;
  z-index: 1;
}

.sources__labels__list {
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid $th-grayish-color;

  &-button {
    width: 22px;
    height: 24px;
    color: $th-highlight-color;
    margin: 4px;
    border: 1px solid $th-highlight-color;
    border-radius: 5px;

    &:hover {
      color: $th-white;
      background: $th-highlight-color;
      opacity: 0.8;
    }
  }

  @media only screen and (min-width: $breakpoint-sm) {

    &-button {
      width: 30px;
      height: 30px;
      margin: 4px 2px;
    }

  }
}

/* ----------------------- */


.width100 {
  width: 100%;
}

.profile-share {

  .fa {
    font-size: 30px;
    padding: 10px;
  }
}

.disabled {
  color: rgba(177, 174, 174, 0.68);
}

.react-tagsinput {

  .input-wrapper {
    display: flex;

    input {
      padding-right: 30px;
    }

    button {
      line-height: 20px;
      margin: 5px -20px 5px 0;
      flex: 0 0 auto;
      position: relative;
      left: -20px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  .react-tagsinput-input {
    width: 100%;
    margin: 5px 0;

    & > input {
      width: 100%;
    }
  }

  .react-tagsinput-tag {
    background: $th-main-color;
    color: $th-tag-font-color;
    display: inline-block;
    padding: 6px 11px;
    border-radius: 20px;
    font-size: 15px;
    word-wrap: break-word;
    margin: 0 10px 10px 0;

    &:last-child {
      margin-right: 0;
    }

    .react-tagsinput-remove {
      color: $th-tag-close-color;
      margin-left: 7px;
      min-width: 12px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .description {
    font-size: 13px;
    margin-bottom: 10px;
    display: inline-block;
  }
}

.personal.word-filtering {
  flex-direction: column;
}

.char-filters {
  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 5px 0;
    }
  }
}
.char-filter-char {
  font-family: monospace;
}


.container {

  .form {
    align-items: left;
  }

  .vote-filter {
    display: flex;
    flex-direction: row;
    position: relative;
    left: 0;

    &-input {
      width: 150px;
      margin: 5px 0;
    }

    .button-group {

      .button {
        margin: 5px 0 5px 20px;
      }
    }
  }

  .description {

    span {
      font-size: 13px;
    }
  }
}

