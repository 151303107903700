@import '../../styles/variables.pcss';

.item-report {
  display: flex;
  align-items: center;
  width: 25px;
  fill: $th-vote-controls;

  &_open {
    fill: #FFFFFF!important;
  }
}

.item-actions-report-container {
  display: flex;
  align-items: center;
  position: relative;
}

.item-report-container {
  padding: 0 5px;
}

.item-report-wrapper {
  display: flex;
  align-items: center;

  &_open {
    background: $th-menubar-background;
    border-radius: 5px;
  }
}

.item-report-options-container {
  font-size: 22px;
  z-index: 1;
  color: $th-vote-controls;
  position: absolute;
  right: 100%;

  .item-report-options-wrapper {
    display: flex;
    flex-direction: column;
    background-color: $th-news-item-bg;
    border: 1px solid $th-news-item-borders;
    overflow: hidden;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .item-report-options {
    display: flex;
    flex-direction: row-reverse;
    padding-bottom: 10px;

    &-header {
      display: flex;
      justify-content: center;
      font-size: 14px;
    }
  }

  .item-report-option {
    position: relative;
    display: flex;
    margin: 5px 20px;

    .item-report-option-label {
      cursor: pointer;
      display: block;
      font-size: 10px;
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
    }
  }
}

.item-votes {
  font-size: 14px;
  margin: 0 3px;
}

.item-voted-up {
  color: $highlight-blue;
  border-radius: 20px;
}

.item-voted-down {
  color: #FF1E00;
  border-radius: 20px;
}

.item-actions-vote-container {
  display: flex;
  align-items: center;
  position: relative;
  gap: 5px;
}

.item-votes-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  flex: 0 0 35px;
  margin: -5px -5px -5px 0;

  &.options-visible {
    display: none;
  }
}

.item-vote-indicator {
  width: 10px;
  padding: 0 5px;
}


.item-vote-up,
.item-vote-down {
  cursor: pointer;
  font-size: 20px;
  line-height: 25px;
  text-align: center;

  @media only screen and (min-width: $breakpoint-list-sm-end) {
    line-height: 28px;
    width: 28px;
  }
}
