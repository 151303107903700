@import '../../styles/variables.pcss';

.search-input {
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  box-shadow: 0 2px 12px -4px #AAAAAA;
  background: white;
  box-sizing: border-box;

  @media only screen and (min-width: $breakpoint-lg) {
    border-radius: 0 0 10px 10px;
  }

  .search-icon {
    display: none;

    @media only screen and (min-width: $breakpoint-sm) {
      display: flex;
      border-right: 1px solid $th-border-color;
      color: #6F6E51;
      text-align: center;
      font-size: 25px;
      padding: 17px 20px;
    }
  }

  &__save-search {
    display: block;
    margin-left: auto;
    margin-right: 10px;

    &:hover {
      color: $th-highlight-color;
    }
  }
}

.search-input-field {
  position: relative;
  display: flex;
  height: 32px;
  flex: 1000 0 50px;
}

.search-input-field-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 $gutter;

  input {
    margin: 0; /* Normalize input height on Safari */
    font-size: 16px;
    padding: 0;
    background: transparent;
  }
}
