@import '../../../styles/variables.pcss';

$medium-weather-breakpoint: 645px;
$large-weather-breakpoint: 696px; /*$breakpoint-sm;*/

.menu-weather {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: $th-lightest-gray;
  position: relative;
  padding-bottom: 13px;

  @media only screen and (min-width: 390px) {
    width: 90px;
  }

  @media only screen and (min-width: $medium-weather-breakpoint) {
      width: 75px;
    }

  @media only screen and (min-width: $large-weather-breakpoint) {
    padding-bottom: 0;
    width: 135px;
  }

  &:hover {
    color: $th-main-color;
  }

  &__symbol-container {
    width: 20px;
    height: 20px;
    margin-right: 3px;

     .weather-symbol {
      transform-origin: 0 0;
      transform: scale(0.35);
    }

    @media only screen and (min-width: $medium-weather-breakpoint) {
      width: 25px;
      height: 25px;
      margin-right: 5px;

       .weather-symbol {
        transform: scale(0.5);
      }
    }

    @media only screen and (min-width: 896px) {
      width: 40px;
      height: 40px;

       .weather-symbol {
        transform: scale(0.8);
      }
    }
  }

  &__data {
    width: auto;
  }

  &__temp {
    height: 13px;
    margin-top: 2px;
    white-space: nowrap;
  }

  &__degree {
    display: none;

     @media only screen and (min-width: $medium-weather-breakpoint) {
      display: inline;
    }
  }

  &__location {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 75px;

    @media only screen and (min-width: 390px) {
      width: auto;
    }

    @media only screen and (min-width: $medium-weather-breakpoint) {
      width: 75px;
    }

    @media only screen and (min-width: $large-weather-breakpoint) {
      position: initial;
      width: 100px;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    font-size: 30px;
  }
}
