.trending-tags-list {
  padding-left: 5px;
  padding-right: 5px;
}

.trending-tags-all-tags-link {
  display: inline-block;
  margin: 5px 0 0 6px;
  font-weight: 600;
  white-space: nowrap;
}
