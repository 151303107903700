@import '../styles/variables.pcss';


/**
 * Ad-slots have proper min-heights, but they are injected to DOM only on client side. This means
 * our initial HTML does not reserve any space for ads. To prevent layout jumping, we reserve
 * space for leaderboard even before JS runs.
 */

.desktop-leaderboard-container {
  @media (min-width: 980px) {
    margin-top: 10px;
    min-height: 400px;
  }
}

.mobile-leaderboard-container {
  @media (max-width: 979px) {
    min-height: 300px;
  }
}

.ad-slot {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: $gutter auto 0 auto;
  overflow: hidden; /* prevent oversized ads from messing whole site */

  iframe {
    display: block;
  }

  &--alma {
    background: url(../assets/small-logo.png) center center no-repeat;
  }

  &--placeholder {
    background: rgba(255, 255, 255, 0.15);
  }

  &--interstitial {
    margin: 0;
  }

  &--leaderboard {
    width: 980px;
    min-height: 400px;
  }

  &--box {
    min-height: 250px;
  }

  &--post-content {
    display: flex;
    margin: 10px auto;
    width: 728px;
    min-height: 90px;

    @media (min-width: 980px) {
      width: 980px;
    }
  }


  &--mob-list,
  &--mob-leaderboard {
    margin-top: 0;
    min-height: 300px;
    background-color: $th-news-list-background;
  }

  &--ajo-list {
    margin-top: 0;
    min-height: 100px;
    background-color: $th-news-list-background;
  }

  &--ajo-side-box {
    margin-top: 0;
    min-height: 100px;
  }

  &--nativead {
    margin-top: 0;
    background: $th-news-list-background;
  }

  &--skyscraper {
    width: 160px;
    min-height: 600px;
  }
}


/* These sidebar slots have in the past received oversized native ads. Instead of cutting */
/* we can align them so they won't cover items list and let them overflow to the right */

#almad-aside-right-3,
#almad-aside-right-4 {
  overflow: visible;
  justify-content: left;
}


/* Container for impression measurement pixels in tickers. */
/* Uses absolute positioning so as not to take any space in the layout. */

.impressions__tracker {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 1px;
  max-height: 1px;
  overflow: hidden;
  visibility: hidden;
}
