@import '../../styles/variables.pcss';

.search-status-indicator {
  margin-right: 10px;

  &__count {
    cursor: pointer;
    border: 1px solid #000000;
    color: #000000;
    border-radius: 12px;
    padding: 0 5px;
    width: max-content;
    height: 22px;
    font-size: 12px;

    @media only screen and (min-width: $breakpoint-sm) {
      font-size: 16px;
    }

    &:hover {
      transition: 0.2s;
      background: $th-border-color;
    }
  }

  &__error {
    color: #000000;
  }

  &__spinner {
    font-size: 25px;
    color: #000000;
  }
}
