@import './variables.pcss';

.padded {
  padding: $gutter;

  &_x {
    padding-left: $gutter;
    padding-right: $gutter;
  }

  &_y {
    padding-top: $gutter;
    padding-bottom: $gutter;
  }
}
