@import '../../styles/variables.pcss';

.news-list-box {
  display: flex;
  flex-direction: column;
  overflow-x: scroll;

  &-header {
    background-color: $th-main-color;
    color: $th-sidebar-box-header;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 15px;

    a {
      color: $th-sidebar-box-header!important;
      display: flex;
      align-items: center;
      overflow: hidden;
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .fa-circle-thin {
      font-size: 1.3em;
    }

    .fa-angle-right {
      font-size: 0.8em;
    }
  }

  &-content {
    background-color: $th-sidebar-box-background;
    font-size: 13px;

    .news {
      border-bottom: 1px solid $th-news-item-borders;
      background: $th-news-box-background;
      padding: 10px;
    }
  }

  @media only screen and (min-width: 897px) {
    display: none;
  }
}

.news-list-box.trending {
  flex-direction: row;
  height: 40px;
  align-items: center;
  padding: 0 5px 0 20px;
  background: $th-top-notification-background;
  overflow-x: auto;
  scrollbar-width: thin;
  border-top: 1px solid $th-news-item-borders;
  border-bottom: 1px solid $th-news-item-borders;

  .trending-header {
    font-weight: bold;
    margin-right: 5px;
  }

  .trending-tag, .trending-all-tags {
    white-space: nowrap;
    text-wrap: nowrap;
    margin: 0 5px;
    color: $th-font-color;
  }

  .trending-all-tags {
    font-weight: bold;
  }
}
