@import '../../styles/variables.pcss';

.weather-title {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;

  h1, h2 {
    font-size: 15px;
    font-weight: bold;
    margin: 0;
    padding: 7px 10px;
  }

  h2 {
    padding-top: 0;
  }

  .weather-title__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;

    .weather-title__actions-button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .weather-title__actions-icon path {
      stroke: $th-highlight-color;
    }

    .display-favorites {
      fill: $th-highlight-color;
      stroke: $th-highlight-color;
    }

    .weather-title__actions-title {
      font-size: 16px;
    }
  }
}

.location-search-dialog {
  position: relative;
  display: flex;
  padding: 10px 10px 6px;
  background: $th-news-list-background;
  color: $th-font-color;
  box-sizing: border-box;

  &__search-container {
    width: 100%;
    position: relative;
  }

  &__input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid $th-border-color;
    border-radius: 5px;
  }

  &__clear-button {
    position: absolute;
    top: 3px;
    right: 20px;
    background: transparent;
    border: none;
    color: $th-font-color;
    font-size: 1.5em;
    cursor: pointer;
  }

  &__geolocation {
    margin-left: 5px;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background: $th-highlight-color;
    color: $th-white;

    .weather-add-geolocation__icon, .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      background: none;
      color: inherit;
      width: 40px;
      height: 40px;
      padding: 0;
    }
  }

  &__results {
    position: absolute;
    z-index: 1;
    background: $th-news-list-background;
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style: none;
    border: 1px solid $th-highlight-color;
    width: 100%;
    border-radius: 5px;

    &-location {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      line-height: 29px;
      margin: 0 5px;

      &-name {
        display: flex;
        width: 100%;
      }

      &-favorite path {
        stroke: $th-highlight-color;
      }

      .active {
        font-weight: bold;
        fill: $th-highlight-color;
        stroke: $th-highlight-color;
      }
    }
  }
}

.default-favorited-location {
  cursor: not-allowed;
  fill: $th-disabled-button;

  path {
    fill: $th-highlight-color!important;
  }
}

.weather-favorite-locations {

  &__current-location {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $th-current-weather-background;
    padding: 5px 10px;

    &-name-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $th-font-color;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 10px;

      &-home {

        path {
          fill: $th-disabled-button;
        }

        &.default-current-location {

          path {
            fill: $th-highlight-color;
          }
        }
      }

      &-star {

        path {
          stroke: $th-highlight-color;
        }

        &.current-favorited-location {
          fill: $th-highlight-color;

          path {
            stroke: $th-highlight-color;
          }
        }
      }
    }
  }

  &__list-container {
    margin: 20px 0 0;
  }

  &__list-title {
    font-weight: bold;
    margin: 20px 10px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0;
    padding: 0;
    list-style: none;
    border-top: 1px solid $th-border-color;

    &-item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $th-border-color;
      padding: 5px;

      &-link {
        display: flex;
        align-items: center;
        color: $th-font-color!important;
        padding: 5px;
        width: 100%;
      }

      &__button {
        margin-right: 15px;

        &-home {

          path {
            fill: $th-disabled-button;
          }
        }

        &-star {
          fill: $th-highlight-color;

          path {
            stroke: $th-highlight-color;
          }
        }
      }
    }
  }
}

.current-weather {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  background: $th-current-weather-background;
  border-top: 1px solid $th-border-color;
  border-bottom: 1px solid $th-border-color;

  &__favorite {
    display: flex;

    &-icon path {
      stroke: $th-highlight-color;
    }
  }

  .active {
    fill: $th-highlight-color;
    stroke: $th-highlight-color;
  }

  &__location-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__location {
    font-size: 22px;
    font-weight: bold;
    color: $th-highlight-color;
    margin: 0 10px;
  }

  &__details-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: $mobile-menu-breakpoint) {
      flex-direction: row;
      gap: 50px;
    }
  }

  &__details {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }

  &__temperature-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: $mobile-menu-breakpoint) {
      margin-right: 50px;
    }
  }

  &__temperature {
    font-size: 42px;
    font-weight: bold;

    &-feelslike {
      font-size: 12px;
      font-weight: normal;
      margin-left: 5px;
      color: #777777;
    }
  }

  &__symbol {
    display: inline-block;
    vertical-align: bottom;
    width: 50px;
    height: 50px;

    @media only screen and (min-width: $mobile-menu-breakpoint) {
      margin-right: 50px;
    }
  }

  &__additional-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (min-width: $mobile-menu-breakpoint) {
      flex-direction: column;
      justify-content: space-around;
    }
  }

  &__wind, &__precipitation, &__precipitation-probability {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $th-gray;
    margin: 0 20px;

    @media only screen and (min-width: $mobile-menu-breakpoint) {
      margin: 10px 0;
    }

    .weather-value {
      margin-left: 5px;
    }
  }
}

.weather-hour {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  border-right: 1px solid $th-border-color;
  flex-direction: column;
  align-items: center;

  .weather-time-date {
    text-transform: capitalize;
    font-size: 14px;
    color: $th-gray;
  }

  .weather-symbol {
    transform: scale(0.7, 0.7);
  }

  .weather-temperature {
    font-size: 22px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

    span {
      white-space: nowrap;
    }

    .weather-temperature-feelslike {
      font-size: 12px;
      color: $th-gray;
    }
  }

  .weather-wind {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $th-gray;
    margin-bottom: 5px;

    .weather-value {
      margin-left: 5px;
    }
  }

  .weather-precipitation {
    display: flex;
    align-items: center;
    color: $th-gray;
    margin-bottom: 5px;

    span {
      white-space: nowrap;
    }
  }

  .weather-precipitation-probability {
    display: flex;
    align-items: center;
    color: $th-gray;
    margin-bottom: 5px;

    span {
      white-space: nowrap;
    }
  }

  &-selector {
    cursor: pointer;
    position: relative;
    /* Disable highlight color on user touch */
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;

    .carousel-left {
      background: linear-gradient(to left, rgba(240,239,228,0) 0%, $th-news-list-background 30%);
      @media (pointer: coarse) and (max-width: $breakpoint-md) {
        display: none;
      }
    }

    .carousel-right {
      background: linear-gradient(to right, rgba(240,239,228,0) 0%, $th-news-list-background 30%);
      @media (pointer: coarse) and (max-width: $breakpoint-md) {
        display: none;
      }
    }
  }
}

/*************************************/
/*      Weather forecast styling     */
/*************************************/

.weekly-weather-day-header {
  padding: 5px;
  background: $th-highlight-color;
  color: $th-white;
}

.weekly-weather-list-wrapper {
  list-style: none;
}

.weekly-weather-list-wrapper:not(:first-child) {
  border-top: 1px solid $th-border-color;
}

.weekly-weather-day {
  cursor: pointer;
}

.weekly-weather-day, .weekly-weather-day__hourly-hour {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $gutter 0;
  box-sizing: border-box;
  width: 100%;

  > * {
    flex: 1;
  }

  &__hourly {
    background: $th-current-weather-background;
    margin: 0 0 0 -10px;
    padding: 0 0 0 10px;
    width: calc(100% + 10px);
    cursor: pointer;

    &-hour {
      border-bottom: none!important;
      border-top: none!important;
    }
  }

  .weather-time {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $th-font-color;
    font-weight: bold;
    font-size: 12px;

    @media only screen and (min-width: $mobile-menu-breakpoint) {
      font-size: 16px;
    }
  }

  .weather-symbol {
    padding: 0 5px;
    scale: 0.7;
    display: flex;
    justify-content: center;
  }

  @media only screen and (min-width: $mobile-menu-breakpoint) {

    .weather-symbol {
      padding: 0 10px;
      scale: 1;
    }
  }

  .weather-temperature-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .weather-temperature {
    font-size: 16px;
    height: 25px;
    font-weight: bold;
  }

  .weather-min-temperature {
    font-size: 12px;
    display: flex;
    align-items: center;
    color: $th-gray;
  }

  .weather-temperature-feelslike {
    font-size: 12px;
    color: $th-gray;
    width: max-content;
  }

  @media only screen and (min-width: $mobile-menu-breakpoint) {

    .weather-temperature {
      font-size: 22px;
    }

    .weather-min-temperature {
      font-size: 16px;
    }

    .weather-temperature-feelslike {
      font-size: 16px;
    }
  }

  .weather-min-max-rain-change {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $th-gray;
    font-size: 12px;

    &__image {
      width: 10px;
      height: 10px;
    }

    &__value {
      width: max-content;
    }

    @media only screen and (min-width: $mobile-menu-breakpoint) {
      font-size: 16px;

      &__image {
        width: 15px;
        height: 15px;
      }
    }
  }

  .weather-wind {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $th-gray;
    font-size: 12px;

    .weather-wind-direction img {
      width: 10px;
      height: 10px;
    }

    @media only screen and (min-width: $mobile-menu-breakpoint) {
      font-size: 16px;

      .weather-wind-direction img {
        width: 15px;
        height: 15px;
      }
    }
  }

  .weather-precipitation-probability {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $th-gray;
    font-size: 12px;

    .weather-precipitation-probability-amount img {
      width: 10px;
      height: 10px;
    }

    @media only screen and (min-width: $mobile-menu-breakpoint) {
      font-size: 16px;

      .weather-precipitation-probability-amount img {
        width: 15px;
        height: 15px;
      }
    }
  }

  .weather-value {
    margin-top: 7px;
  }
}

.weather-wind .S {
  transform: rotate(225deg);
}

.weather-wind .SW {
  transform: rotate(275deg);
}

.weather-wind .W {
  transform: rotate(315deg);
}

.weather-wind .NW {
  transform: rotate(0deg);
}

.weather-wind .N {
  transform: rotate(45deg);
}

.weather-wind .NE {
  transform: rotate(90deg);
}

.weather-wind .E {
  transform: rotate(135deg);
}

.weather-wind .SE {
  transform: rotate(180deg);
}

.weather-loading {
  transition: 1.5s ease-in;
  opacity: 0.6;
}

.weather-loading-indicator {

  .loading {
    position: relative;
    top: 5px;
    font-size: 25px;
    color: black;
    text-transform: uppercase;
    border: none;
    width: 33px;
    height: 33px;
    text-align: center;
    box-sizing: border-box;
    margin-top: -8px;
    padding: 0;
  }
}

.weather-symbol span {
  display: inline-block;
  vertical-align: bottom;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-image: url(../../assets/weather-symbols.png);
}

/* Weather symbol mappings */

.weather-symbol .d000 {
  background-position: 0 0;
}

.weather-symbol .d100 {
  background-position: -50px 0;
}

.weather-symbol .d200 {
  background-position: -100px 0;
}

.weather-symbol .d210 {
  background-position: -150px 0;
}

.weather-symbol .d211 {
  background-position: -200px 0;
}

.weather-symbol .d212 {
  background-position: -250px 0;
}

.weather-symbol .d220 {
  background-position: -300px 0;
}

.weather-symbol .d221 {
  background-position: -350px 0;
}

.weather-symbol .d222 {
  background-position: -400px 0;
}

.weather-symbol .d240 {
  background-position: -450px 0;
}

.weather-symbol .d300 {
  background-position: -500px 0;
}

.weather-symbol .d310 {
  background-position: -550px 0;
}

.weather-symbol .d311 {
  background-position: -600px 0;
}

.weather-symbol .d312 {
  background-position: -650px 0;
}

.weather-symbol .d320 {
  background-position: -700px 0;
}

.weather-symbol .d321 {
  background-position: -750px 0;
}

.weather-symbol .d322 {
  background-position: -800px 0;
}

.weather-symbol .d340 {
  background-position: -850px 0;
}

.weather-symbol .d400 {
  background-position: -900px 0;
}

.weather-symbol .d410 {
  background-position: -950px 0;
}

.weather-symbol .d411 {
  background-position: -1000px 0;
}

.weather-symbol .d412 {
  background-position: -1050px 0;
}

.weather-symbol .d420 {
  background-position: -1100px 0;
}

.weather-symbol .d421 {
  background-position: -1150px 0;
}

.weather-symbol .d422 {
  background-position: -1200px 0;
}

.weather-symbol .d430 {
  background-position: -1250px 0;
}

.weather-symbol .d431 {
  background-position: -1300px 0;
}

.weather-symbol .d432 {
  background-position: -1350px 0;
}

.weather-symbol .d440 {
  background-position: -1400px 0;
}

.weather-symbol .d500 {
  background-position: -1450px 0;
}

.weather-symbol .d600 {
  background-position: -1500px 0;
}

.weather-symbol .n000 {
  background-position: -1550px 0;
}

.weather-symbol .n100 {
  background-position: -1600px 0;
}

.weather-symbol .n200 {
  background-position: -1650px 0;
}

.weather-symbol .n210 {
  background-position: -1700px 0;
}

.weather-symbol .n211 {
  background-position: -1750px 0;
}

.weather-symbol .n212 {
  background-position: -1800px 0;
}

.weather-symbol .n220 {
  background-position: -1850px 0;
}

.weather-symbol .n221 {
  background-position: -1900px 0;
}

.weather-symbol .n222 {
  background-position: -1950px 0;
}

.weather-symbol .n240 {
  background-position: -2000px 0;
}

.weather-symbol .n300 {
  background-position: -2050px 0;
}

.weather-symbol .n310 {
  background-position: -2100px 0;
}

.weather-symbol .n311 {
  background-position: -2150px 0;
}

.weather-symbol .n312 {
  background-position: -2200px 0;
}

.weather-symbol .n320 {
  background-position: -2250px 0;
}

.weather-symbol .n321 {
  background-position: -2300px 0;
}

.weather-symbol .n322 {
  background-position: -2350px 0;
}

.weather-symbol .n340 {
  background-position: -2400px 0;
}

.weather-symbol .n400 {
  background-position: -900px 0;
}

.weather-symbol .n410 {
  background-position: -950px 0;
}

.weather-symbol .n411 {
  background-position: -1000px 0;
}

.weather-symbol .n412 {
  background-position: -1050px 0;
}

.weather-symbol .n420 {
  background-position: -1100px 0;
}

.weather-symbol .n421 {
  background-position: -1150px 0;
}

.weather-symbol .n422 {
  background-position: -1200px 0;
}

.weather-symbol .n430 {
  background-position: -1250px 0;
}

.weather-symbol .n431 {
  background-position: -1300px 0;
}

.weather-symbol .n432 {
  background-position: -1350px 0;
}

.weather-symbol .n440 {
  background-position: -1400px 0;
}

.weather-symbol .n500 {
  background-position: -2450px 0;
}

.weather-symbol .n600 {
  background-position: -2500px 0;
}


.weather-data-from {
  padding: 0 $gutter $gutter $gutter;
  text-align: right;
}
