@import '../../styles/variables.pcss';

.top-menu {

  &__container {

    &_inner {
      margin: 0 auto;
      padding: 0 calc($gutter / 2) ;
      height: $menu-height;
      font-size: 15px;
      font-weight: bold;
    }

    &_outer {
      display: block;
      background-color: $th-menubar-background;
      position: relative;
      z-index: 22;
    }
  }
}

.menu-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: $menu-height;
  width: 100%;

  &__column {
    margin: 0 4px;

    @media only screen and (min-width: 340px) {
      margin-left: 5px;
    }

    &_logo {
      margin-left: 0;

      @media only screen and (min-width: $breakpoint-sm) {
        margin-right: 25px;
      }
    }

    &_pages {
      display: none;
      margin-right: auto;

      @media only screen and (min-width: $mobile-menu-breakpoint) {
        display: block;
      }
    }

    &_weather {
      display: none;

      @media only screen and (min-width: 320px) {
        display: block;
        margin-left: auto;
        min-width: 80px;
      }
    }

    &_stats {
      display: none;
      margin-left: 20px;

      @media only screen and (min-width: 866px) {
        display: block;
      }
    }

    &_search {
      margin-left: auto;
      order: initial;
      font-size: 22px;
      padding: 5px 10px;

      @media only screen and (min-width: $mobile-menu-breakpoint) {
        display: block;
      }
    }

    &_login {
      order: 5;
    }
  }
}
