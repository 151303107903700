@import '../../styles/variables.pcss';

.il-sports__content {
  .il-scoreboard {
    margin: 0 10px;
  }

  /* Hide background as it works better with themes */
  .il-tulospalvelu__sport-picker__wide__list {
    background: none;
  }

  .il-tulospalvelu__date-picker__list {
    scrollbar-width: thin;
  }

  /* Hide button as it routes to /tulospalvelu/tilastot/* which Ampparit doesn't have */
  .il-tulospalvelu__serie-results__header__actions__statistics {
    display: none;
  }


  &.dark {

    /* Do not affect to specific containers, Veikkaus etc. */
    :not(.il-tulospalvelu__common__team-logo__image,
    .il-tulospalvelu__veikkaus__veikkaus-ad__image,
    .il-tulospalvelu__date-picker__list__item--selected,
    .il-tulospalvelu__date-picker__list__item--current,
    .tulospalvelu__footer__k18) {
      filter: invert(1);

      .il-tulospalvelu__sport-event__match, .il-tulospalvelu__match-event, .il-tulospalvelu__date-picker {
        background-color: $th-news-list-background;
      }

      .il-tulospalvelu__sport-event__score,
      .il-tulospalvelu__sport-event__basic-info__score,
      .il-tulospalvelu__sport-event__event-start-time__label,
      .il-tulospalvelu__match-event--time__label,
      .il-tulospalvelu__date-picker__list__item--current {
        color: $th-font-color;
      }
    }
  }

  /* If Ampparit should not show Veikkaus related things.. */
  /* .il-tulospalvelu__veikkaus__veikkaus-ad__image,
  .il-tulospalvelu__veikkaus,
  .tulospalvelu__footer {
    display: none;
  } */

}
