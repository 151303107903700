@import './variables.pcss';

body {
  text-size-adjust: 100%;
}

a img {
  border: none;
}

body.lw-normal .outer-container {
  max-width: $content-width;
}

body.lw-wide .outer-container {
  max-width: $content-width-wide;
}

body.lw-full .outer-container {
  max-width: $content-width-full;
}

.skip-link:focus {
  color: #FFFFFF!important;
  background: #171717!important;
  padding: 10px!important;
  display: inline-block;
  font-size: 20px;
  position: fixed!important;
  top: 50px;
  z-index: 9999;
}

.side-padding-20 {
  padding: 0 20px;
}

.Dropdown-root {
  color: $th-dropdown-border-color;
  border: 1px solid $th-dropdown-border-color;
  border-radius: 15px;
  width: 135px;
  position: absolute;

  > * {
    padding: 5px 12px 5px 7px;
    border-radius: 15px;
  }

  * + * {
    padding-top: 0;
  }

  .Dropdown-control {
    position: relative;
  }

  .Dropdown-placeholder {
    font-size: 13px;
    line-height: 17px;
    cursor: default;
  }

  .Dropdown-title {
    color: #808080;
    padding: 4px 0 2px 0;
    border-bottom: 1px solid;
    margin-top: 2px;
  }

  .Dropdown-option {
    font-size: 13px;
    padding: 2px 5px;
    cursor: pointer;
    @media (pointer: coarse) {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  .hidden {
    display: none;
  }

  .Dropdown-arrow::before {
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    content: '';
    display: inline-block;
    height: 0.35em;
    position: absolute;
    right: 10px;
    top: 10px;
    transform: rotate(135deg);
    transition: transform 0.2s;
    vertical-align: top;
    width: 0.35em;
  }

  .Dropdown-placeholder {
    margin-right: 10px;
  }

  &.is-open {
    border: 1px solid $th-dropdown-border-color;
    background-color: $th-news-list-background;
    z-index: 2;

    .Dropdown-arrow:before {
      transform: rotate(315deg);
    }
  }

  &:not(.is-open) {

    > * {
      border: none !important;
    }
  }
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.outer-container {
  margin: 0 auto;
  width: auto;
  max-width: $content-width;
}

.underline-text {
  text-decoration: underline;
}

.blur {
  filter: blur(10px);
}

.blacklist-container {
  width: 100%;
}

.empty-list {
  background-color: white;
  text-align: center;
}

.center-text {
  text-align: center;
}

.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.72);
  z-index: 23;
  width: 100%;
  height: 100%;
}

.sidebar-container {
  display: none;
}

.empty {
  height: 100%;
}

.container {
  width: 100%;
}

.content {
  flex: 1 1 auto;
  min-width: 33%; /* For some reason this affects to weather page -- DO NOT REMOVE (until clarified) */
  width: 100%;
  box-sizing: border-box;
}

.search-container {
  z-index: 23;
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.main-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
}

.fold-container {
  width: 100%;
}

.inbody-sidebar-container {
  order: 99;
  display: flex;
  width: 100%;

  .inbody-sidebar {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.notification-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: center;
  min-width: 60px;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    max-width: 80%;
  }

  .Toastify__toast {

    &--success {
      color: #000000;
      background-color: #E8F7E7;
      border: 1px solid #3DAE3D;
    }

    &--info {
      color: #000000;
      background-color: #E8F7F9;
      border: 1px solid #00A4C2;
    }

    &--error {
      color: #000000;
      background-color: #F9E8E7;
      border: 1px solid #CB4A28;
    }
  }
}

.empty-message, .unable-to-load {
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  color: $th-gray;
  display: flex;
  font-style: italic;
  width: 100%;
  justify-content: center !important;
  margin: 100px 0;
}

@media only screen and (min-width: $mobile-menu-breakpoint) {

  .container {
    margin-top: $gutter;
  }
}

@media only screen and (min-width: $breakpoint-md) {

  .outer-container {
    width: auto;
  }

  .overlay {
    display: none;
  }

  .sidebar-container {
    display: block;
    width: 300px;
    flex: 0 0 300px;
    margin-left: $gutter;
  }
}

.settings-nav {
  margin-bottom: 10px;

  li a {
    padding-left: 5px;
    padding-right: 5px;
  }

  .active {
    box-sizing: border-box;
    border-top: 1px solid $th-border-color;
    border-left: 1px solid $th-border-color;
    border-right: 1px solid $th-border-color;
  }
}

.alma-footer .right {
  float: right!important;
}
