@import '../../styles/variables.pcss';

.loading {
  text-align: center;
  font-size: 24px;
  color: $th-font-color;
  background-color: $th-news-list-background;
  height: 100%;
  font-weight: bold;
  padding: 10px;
}



.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
@keyframes loading-spinner {
  from { transform:rotate(0deg); }
  to { transform:rotate(360deg); }
}
.loading-spinner {
  position: relative;
  top: -5%;
  width: 30px;
  height: 30px;
  animation-name: loading-spinner;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
