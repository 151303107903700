@import '../../styles/variables.pcss';

.menu-namedays {
  span {
    color: $th-lightest-gray;
  }

  &__date {
    white-space: nowrap;
    display: block;
  }

  &__names {
    font-size: 12px;
    display: block;
    font-weight: lighter;
  }
}
