@import 'variables.pcss';

.share-widget {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 31px;

  &_open {
    background: $th-menubar-background;
    border-radius: 5px;
    fill: #FFFFFF;
  }

  .share-text {
    padding: 0 5px;
    height: 22px;
  }

  .share-menu-container {
    font-size: 15px;
    z-index: 1;
    color: $th-vote-controls;
    position: absolute;
    right: auto;
    top: 100%;

    @media only screen and (min-width: $breakpoint-xs) {
      right: 100%;
      top: auto;
    }
  }

  .share-menu-wrapper {
    display: flex;
    flex-direction: column;
    background-color: $th-news-item-bg;
    border: 1px solid $th-news-item-borders;
    overflow: hidden;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .share-menu {
    display: flex;
    flex-direction: row;
    padding: 5px 5px 0;
  }

  .share-menu-title {
    display: flex;
    justify-content: center;
    font-size: 14px;
  }

  .fa-stack {
    padding: 2px;
    cursor: pointer;

    span {
      /* For some reason stacked icons inside buttons are aligned differently
         from the icons inside links. This fixes it. */
      top: 0;
    }
  }

  .share-twitter {

    .fa-twitter {
      color: #FFFFFF;
    }

    .fa-circle {
      color: #1DA1F2;
    }
  }

  .share-facebook {

    .fa-facebook {
      color: #FFFFFF;
    }

    .fa-circle {
      color: #3B5998;
    }
  }

  .share-email {

    .fa-envelope {
      color: #FFFFFF;
    }

    .fa-circle {
      color: gray;
    }
  }

  .share-whatsapp {

    .fa-whatsapp {
      color: #FFFFFF;
    }

    .fa-circle {
      color: #25D366;
    }
  }

  .share-url {

    .fa-link {
      color: #FFFFFF;
    }

    .fa-circle {
      color: #00A4C2;
    }

    .fa-circle-thin {
      color: #00A4C2;
    }
  }
}
