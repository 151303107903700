@import '../../styles/variables.pcss';

.feeds-box-display {
  margin: 20px 0;
}

.add-feed {
  display: flex;
  padding: 5px 0;

  &__input {
    width: 100%;
    padding-right: 30px;
  }
  &__button {
    flex: 0 0 auto;
    margin-left: -20px;
  }
}

.add-feed-description {
  font-size: 13px;
}


.user-feed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  background: $th-main-color;
  color: $th-tag-font-color;
  padding: 6px 11px;
  margin: 10px 0;
  border-radius: 20px;

  line-height: 18px;
  font-size: 15px;

  &__data {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    padding: 5px 0 5px 5px;

    @media only screen and (min-width: $breakpoint-sm) {
      flex-direction: row;
      align-items: center;
      padding: 0;
    }
  }

  &__url {
    margin-right: auto;
  }

  &__category {
    flex: 0 0 auto;
    width: 190px;
  }

  &__remove {
    padding: 5px;
    color: $th-tag-close-color;
  }
}
