@import '../../styles/variables.pcss';

.carousel-items {
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;
  overflow-x: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.carousel-left, .carousel-right {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  z-index: 1;
  font-size: 45px;

  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  span {
    display: inline;
  }
}

.carousel-left {
  left: 0;
  background: linear-gradient(to left, rgba(240,239,228,0) 0%, $th-nav-background-color 30%);
}

.carousel-right {
  right: 0;
  background: linear-gradient(to right, rgba(240,239,228,0) 0%, $th-nav-background-color 30%);
}

