@import '../../styles/variables.pcss';

.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  margin: 10px auto 0 auto;
  padding: 20px 0rem 20px 2rem;
  background-color: $th-menubar-background;
  color: #E3E3DB;
  font-size: 16px;
}

.footer__column {
  padding: 0 calc($gutter * 2);
  box-sizing: border-box;
  width: 100%;

  @media only screen and (min-width: $breakpoint-sm) {
    width: 50%;
  }
  @media only screen and (min-width: 1000px) {
    width: 25%;
    max-width: 315px;
  }

  .footer__title {
    display: inline-block;
    margin: 0 0 3px 0;
    font-size: 16px;
    font-weight: bold;
    color: $th-main-color;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 1em;
    margin-top: 0;
  }

  .footer__link {
    color: $th-white;
    display: inline-block;
    padding: 0.1em 0;

    &:visited {
      color: $th-light-gray;
    }

    &:link:hover {
      color: $th-main-color;
    }
  }
}
