@import '../../styles/variables.pcss';

.items-list-header {
  position: relative;
  z-index: 2;
  background-color: $th-news-list-background;
  border-bottom: 1px solid $th-news-item-borders;
  display: flex;
  align-items: center;
  padding: 5px 0;
  container-type: inline-size;
  width: 100%;

  .list-options {
    position: static;
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-top: 4px;

    &-toggle {
      padding: 5px 10px 5px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 4px;

      &__whitelisted {
        background-color: $highlight-blue;
        color: #FFFFFF;
      }

      &__blacklisted {
        background-color: $th-dark-gray;
        color: #FFFFFF;
      }

      .fa {
        margin-left: 5px;
      }
    }
  }

  .category-switch__menu-container {
    position: absolute;
    padding: 4px;
    margin-left: 30px;
    border: 1px solid $highlight-blue;
    background-color: #FFFFFF;
    overflow: hidden;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
  }
}
