@import './visibility.pcss';
@import './utility.pcss';
@import './variables.pcss';
@import './button.pcss';
@import './normalize.pcss';
@import './layout.pcss';
@import './navigation.pcss';
@import './components.pcss';

body {
  color: $th-font-color;
  background-color: $th-website-background;
  min-width: 320px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  margin: 0;

  /* Normalize font rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  &.overlay-open, &.overlay-open #react-view {
    overflow: hidden;
    height: 100vh;
  }
}

a, a:hover, a:active {
  transition: color 0.2s;
}

hr {
  margin: 15px 0;
}

.content {
  background: $th-news-list-background;

  &_padded {
    padding: 10px;
  }

  &_no-bg {
    background-color: transparent !important;
  }
}



noscript {
  display: block;
  padding: 10px;
  text-align: center;
  background: white;
  color: #dd0000;
}

@keyframes spin {

  from { transform: rotate(0deg); }

  to { transform: rotate(360deg); }
}
.spin {
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@media (max-width: 767px) {
  .desktop {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .mobile {
    display: none !important;
  }
}

@media (min-width: $breakpoint-md) {
  .screen-md {
    display: none !important;
  }
}

@media (min-width: $breakpoint-sm) {
  .screen-xs {
    display: none !important;
  }
}

@media (max-width: $breakpoint-sm) {
  .screen-sm {
    display: none !important;
  }
}
