@import '../../styles/variables.pcss';

.info-page-form,
.info-page-form-thanks,
.info-page-form-error {
  margin: 20px auto;
  max-width: 720px;
  padding: 20px 20px 10px 20px;
  background-color: rgba(125, 125, 125, 0.1);
}

.info-page-form-thanks,
.info-page-form-error {
  padding-bottom: 40px;
  text-align: center;
  h2 {
    margin-top: 0;
  }
}

.form-group-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 15px;

  @media only screen and (min-width: $breakpoint-sm) {
    flex-direction: row;
    align-items: center;
    padding-bottom: 12px;
  }
}

.form-group-label {
  flex: 1;
  text-align: left;
  max-width: 230px;
  padding: 3px 10px 3px 0;
  font-size: 18px;
  line-height: 20px;
  @media only screen and (min-width: $breakpoint-sm) {
    padding-top: 3px;
    text-align: right;
  }

  &__info {
    font-size: 14px;
    color: $th-gray;
  }

  .info-page-form--feedback & {
    max-width: 180px;
  }
}

.form-group-inputs {
  flex: 1;

  textarea,
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'],
  input[type='url'] {
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid $th-highlight-color;
  }
  textarea {
    height: 100px;
    resize: vertical;
  }
  input[type='number'] {
    width: auto;
  }


  button,
  input[type='button'],
  input[type='submit'] {
    margin-top: 20px;
    min-width: 200px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
  }

  label {
    display: inline-block;
    margin-top: 2px;
    width: 100%;
  }
}


.form-validation-message {
  margin-top: 2px;
  color: $error-color;
}
