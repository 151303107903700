@import '../styles/variables.pcss';

/* CooperationBanner */

.telkku-valioliiga {
  max-height: 150px;
}


.cooperationbanner-iframe {
  width: 300px;
  height: 300px;
  border: none;
  display: block;
  margin: 0 auto;
}

.cooperationbanner-newslist-container {
  border-bottom: 1px solid $th-news-item-borders;
  background: $th-ad-placeholder;

  /* Pull banner up if screen is wide enough that the "time label" will not cover it */
  @media (min-width: 780px) {
    margin-top: -20px;
  }
  @media (min-width: 600px) and (max-width: $mobile-menu-breakpoint) {
    margin-top: -20px;
  }

  .cooperationbanner-iframe {
    width: 320px;
  }
}
