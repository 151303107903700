@import '../../styles/variables.pcss';

.searchbox {
  padding: 7px 20px;
  border-bottom: 1px solid $th-news-item-borders;

  display: flex;
  flex-direction: row;

  .searchbox-header {
    color: $th-news-header;
    padding: 5px 10px 0 0;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    min-width: 90px;

    a {
      color: $th-news-header;
    }
  }

  .searchbox-content {
    background-color: $th-news-list-background;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    &:after {
      content: '';
      display: table;
      clear: both;
    }

    form {
      display: flex;
      flex-direction: row;
    }

    input {
      flex: 1 1 auto;
      padding: 6px;
      width: 170px;
    }
  }
}
