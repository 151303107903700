@import '../../styles/variables.pcss';

.all-tags {

  table {
    border-collapse: collapse;
    width: 100%;
  }

  thead {
    color: $th-sidebar-box-header;
    background-color: $th-main-color;
  }

  th {
    padding: 8px 4px;
  }

  &__page {
    margin: 0 10px;
  }

  &__info {
    margin: 10px;
  }

  &__pinned-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 230px;
  }

  &__pinned-table {
    flex: 1 0 230px;
    height: fit-content;
  }

  .all-tags__search-results-table-head {
    position: sticky;
    top: $menu-height;
    background-color: white;

    th {
      cursor: pointer;
    }
  }

  &__pinned-table, &__search-results-table {
    font-size: 13px;

    &-head {

      th {
        text-align: center;
        word-wrap: break-word;
      }

      .arrow-toggle__button {
        margin-left: 5px;
        width: 10px;
      }
    }

    @media screen and (max-width: $breakpoint-xs) {

      .all-tags__search-results-table-head th {
        max-width: 42px;
      }
    }

    &-body {
      width: 100%;

      td {
        text-align: center;
        padding: 2px 2px 2px 0;
      }
    }

    th:first-child, td:first-child {
      text-align: left;
      max-width: 155px;
    }

    &__no-results {
      text-align: center;
      padding: 10px;
      font-size: 18px;
      font-weight: bold;
      color: $th-gray;
    }
  }

  &__search {
    margin: 0 10px;
    width: 100%;

    &-input {
      margin-bottom: 10px;
      border-radius: 5px;

      &-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__tag-count {
          margin-left: 10px;
          margin-top: 5px;
          color: $th-gray;
        }
      }
    }
  }
}
