@import '../../styles/variables.pcss';

.items-queue {
  pointer-events: none;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2px 0;
  position: absolute;

  @media only screen and (min-width: $breakpoint-sm) {
    flex: 1;
    align-items: center;
  }

  &__content {
    display: flex;
    justify-content: center;

    @media only screen and (min-width: $breakpoint-md) {
      position: relative;
    }
  }

  &__button {
    font-weight: bold;
    font-size: 14px;
    border-radius: 15px;
    background-color: $th-queue-element;
    padding: 5px 10px;
    color: $th-white;
    pointer-events: auto;
    box-shadow: 0 2px 10px rgba(30, 30, 30, 0.2);

    @media only screen and (min-width: $breakpoint-sm) {
      box-shadow: none;
    }
  }

  &__content-arrow {
    margin: 0 5px;
  }

  &_new-items {

    @media only screen and (min-width: $breakpoint-sm) {
      background: $th-queue-element-bg;
    }
  }
}

.items-refresh {
  display: flex;
  justify-content: flex-end;
  margin: 2px 10px;
}
