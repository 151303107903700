@import '../../styles/variables.pcss';

/* Ticker Styles */

.ticker {
  margin: 0 auto;
  display: flex;
  font-size: 14px;
  padding: 5px 0;
  position: relative;

  &--navi {
    justify-content: center;
  }

  &--bottom {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 720px;
    width: 90%;
    z-index: 99999;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
    font-size: 13px;
    transition: all 0.2s ease-out;

    @media only screen and (min-width: $breakpoint-sm) {
      font-size: 14px;
    }
  }

  a {
    /* disable-next-line no-important rule */
    color: inherit !important;
  }

  &--navi &__content-wrapper {
    justify-content: center;
    flex-grow: unset;
  }

  /* content-wrapper is either <a> or <div>, dependeing on if it is a link */

  &__content-wrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;

    /* If content-wrapper itself is a link, no underline */

    a& {
      text-decoration: none;
    }

    a&:hover {
      text-decoration: underline;
    }

    /* But link *inside* content-wrapper will be underlined */

    a {
      text-decoration: underline;
    }

    a:hover {
      opacity: 0.7;
    }
  }

  &__image {
    margin-left: 8px;
    height: 40px;
    width: auto;
  }

  &__text {
    margin-left: 7.5px;
    color: inherit;
    flex-grow: 1;
  }

  &__close-button {
    margin-left: 4px;
    padding: 10px 20px;
    color: inherit;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }
}

@media (max-width: $mobile-menu-breakpoint) {

  .mobile-nav-position {
    position: fixed;
    bottom: 50px;
    left: 50%;
    transition: all 0.2s ease-in;
  }
}

