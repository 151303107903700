@import '../../styles/variables.pcss';

.search-results-empty {
  padding: $gutter $gutter calc(2 * $gutter);
  text-align: center;
  background-color: $th-news-list-background;

  &__icon {
    font-size: 120px;
    color: $th-mustard-darker-color;
  }

  &__title {
    font-size: 20px;
    color: $th-mustard-darker-color;
    margin-bottom: calc(2 * $gutter);
  }

  &__text {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
  }
}
