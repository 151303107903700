@import './variables.pcss';

.button {
  background: $th-highlight-color;
  color: $th-white !important;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  border-radius: 30px;
  padding: 10px 20px;

  &:hover {
    text-decoration: none;
    background: $th-button-element-hover;
  }

  &:disabled, &.disabled {
    background: $th-disabled-button;
    cursor: not-allowed;
  }

  &_large {
    border-radius: 30px;
    display: block;
    margin-top: 15px;
    font-size: 14px;
    padding: 10px 15px;

    .button-text {
      line-height: 35px;
      color: $th-user-menu-link-text-color;
    }

    .button-icon {
      font-size: 35px;
      color: $th-white;
      float: left;
    }
  }

  &_full-width {
    width: 100%;
  }

  &_overlap-left {
    position: relative;
    left: -20px;
    margin-right: -20px;
    padding: 10px 14px;
  }

  &_overlap-right {
    position: relative;
    right: -20px;
    margin-left: -20px;
    padding: 10px 14px;
  }

  &_slim {
    line-height: 20px;
    padding: 0 10px;
    font-size: 13px;
  }
}
