@import 'variables.pcss';

.navigation {

  a, a:visited, a:active, input {
    color: $th-font-color;
  }

  a:hover {
    color: $th-font-color;
    transition: 0.2s;
  }
}

.navigation {
  background-color: $th-nav-background-color;
  color: $th-font-color;
  text-align: center;
  font-size: 15px;
  font-weight: bold;

  ul {
    margin: 0;
    padding: 0;
    border-top: 1px solid $th-nav-background-color;
    border-left: 1px solid $th-nav-background-color;
    border-right: 1px solid $th-nav-background-color;
    display: flex;
  }

  li {
    min-height: 43px;
    display: flex;
    flex: 1 0 auto;
    list-style: none;

    a {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  li.active {
    background-color: $th-news-list-background;

    a {
      color: $th-nav-active-tab-text-color;
    }
  }
}
