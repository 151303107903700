@import '../../styles/variables.pcss';

.info-page {
  box-sizing: border-box;
  padding: 40px 10% 50px;
  width: 100%;
  line-height: 1.3;
  @media only screen and (max-width: $breakpoint-md) {
    padding-left: 5%;
    padding-right: 5%;
  }
  @media only screen and (max-width: $breakpoint-sm) {
    padding-left: $gutter;
    padding-right: $gutter;
  }

  h1 {
    text-align: center;
    margin: 0.5em 0 1.2em;
  }

  h2 {
    margin: 1.5em 0 0.4em;
    font-size: 24px;
  }

  h3 {
    font-size: 16px;
  }

  p {
    margin: 5px 0 15px;
  }

  code {
    font-family: ui-monospace, monospace;
    color: $th-highlight-color;
    margin: 0 3px;
  }

  ul {
    list-style: disc;
    margin: 5px 20px 20px;
    padding-left: 20px;

    li {
      margin-bottom: 10px;
    }
  }

  .sources-list {
    column-width: 205px;
    padding: 0 $gutter;
  }

  &__image {
    width: 100%;
  }
}

.app-lander__app-links {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 20px;

  img {
    max-width: 100%;
    max-height: 60px;
  }
}

