.social-media-links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 34px;

  a.some-fb .fa {
    font-size: 48px;
    color: #3B5998;
  }
  a.some-tw .fa {
    font-size: 48px;
    color: #4099FF;
  }
}

.social-media-feedback-link {
  text-align: center;
  padding-top: 20px;
}
