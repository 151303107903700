@import 'variables.pcss';
@import 'items/item-list.pcss';
@import 'items/more-items.pcss';

.header-register-link {
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
  color: $th-save-temp-profile-color;

  span {
    text-decoration: underline;
  }

  &:hover {
    text-decoration: none;
    color: $th-save-temp-profile-hover!important;
  }
}


.time-label {
  background: $th-news-item-bg;
  height: 0;
  padding-bottom: 20px;

  span {
    min-width: 40px;
    background: $th-time-separator;
    color: $th-time-separator-label;
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
    padding: 2px 4px 4px;
    border-radius: 0 8px 8px 0;
    position: relative;
    top: -5px;
    white-space: nowrap;
    max-height: 15px;
  }

  &_almaajobanner {
    background: $th-ad-placeholder;
  }
}


.no-items {
  text-align: center;
  padding: 40px 0;

  > * {
    margin: 20px;
  }

  i {
    font-size: 48px;
    color: $th-font-color;
  }
}

.grouped-items-section {
  background: $th-news-list-background;
  width: 100%;
  margin-bottom: $gutter;

  h3 {
    padding: 20px 20px 10px 20px;
    margin-bottom: 0;
  }
}

