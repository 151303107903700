@import './variables.pcss';

.scrollup-button {
  font-size: 45px;

  &__arrow {
    color: $th-scroll-up-btn-bg;
  }

  &__bg {
    color: white;

    &::before {
      font-size: 40px;
    }
  }
}

.scrollup-button-wrapper {
  position: fixed;
  bottom: 100px;
  right: 30px;
}
