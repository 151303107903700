@import '../../styles/variables.pcss';

.menu-profile {
  display: flex;

  span {
    color: $th-lightest-gray;
  }

  &__button {
    display: flex;

    &-text {
      border-radius: 5px;
      text-align: center;
      display: inline-block;
      border: none;
      box-sizing: border-box;
      cursor: pointer;
      max-width: 70px;
      padding: 10px 5px;

      &:hover {
        background-color: $th-highlight-color-hover;
      }

      @media only screen and (min-width: 390px) {
        max-width: 80px;
      }

      &_logged-out {
        display: flex;
        align-items: center;
        font-weight: lighter;
        border-radius: 5px;

        &-logo {
          width: 1.35em;
          height: 1.35em;
          fill: $th-white;
        }

        &.animated &-logo {
          animation: glow 0.4s linear infinite alternate;
          border-radius: 50%;
        }

        @keyframes glow {

          0%, 100% {
            box-shadow: 0;
            background-color: transparent;
          }

          50% {
            box-shadow: 0 0 40px $th-white, 0 0 80px $th-white, 0 0 120px $th-white;
            background-color: $th-white;
          }
        }
      }

      &_logged-in {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background: $th-highlight-color;
        font-size: 13px;
      }

      > .fa {
        font-size: 1.5em;
      }

      @media only screen and (min-width: $breakpoint-lg) {

        &_logged-in {
          padding: 10px;
          max-width: 140px;
        }
      }
    }
  }
}

