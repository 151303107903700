@import '../styles/variables.pcss';

.user-management {
  padding: $gutter;
}

.form-action {
  cursor: pointer;
  display: flex;
  margin-top: 10px;
}
