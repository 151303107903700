@import '../../styles/variables.pcss';

.quickfilter {
  padding: 10px;
  margin-bottom: 35px;
  color: $th-grayish-color;
  font-size: 14px;
  font-weight: bold;

  .Dropdown-option {
    margin: 5px 0;
  }

  .icon:before {
    font-family: 'FontAwesome';
    display: inline-block;
    margin-right: 5px;
    font-weight: lighter;
    text-align: center;
    width: 14px;
  }

  .eye:before {
    content: '\f06e'
  }

  .news:before {
    content: '\f1ea';
  }

  .entertainment:before {
    content: '\f006';
  }

  .sports:before {
    content: '\f1e3';
  }

  .Dropdown-root {
    .login,
    .settings {
      font-size: 10px;
      color: $th-gray;
      text-decoration: underline;
      margin: 5px 0;
    }

    &.is-open {
      background-color: $th-category-tree-background;
    }
  }
}
