@import '../styles/variables.pcss';

.recommendations {
  height: auto;

  &-header {
    background-color: $th-recommendations-header-bg;
    color: $th-recommendations-header;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;

    &__title {
      margin: 10px 20px;
      font-size: 15px;
      font-weight: bold;
    }

    &__accept {
      margin-right: 10px;
      font-size: 13px;
      border: 1px solid;
      border-radius: 5px;
      height: fit-content;
      padding: 4px;
    }

    &__reject {
      margin-left: auto;
      margin-right: 7px;
      width: 26px;
      height: 26px;
    }
  }

  &-content {
    background: $th-news-list-background;
    border-bottom: 1px solid $th-news-item-borders;

    &__news {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 10px 5px 10px;

      .simple-item {
        margin: 5px 0;
        padding: 10px 5px 10px 15px;
        width: auto;

        &:not(:first-child) {
          border-top: 1px solid $th-highlight-color;
        }

        @media only screen and (min-width: $breakpoint-sm) {
          width: 33%;
          padding: 5px 5px 0 10px;
          border-top: none!important;

          &:not(:first-child) {
            border-left: 1px solid $th-highlight-color;
          }
        }

        .item-details__detail_category,
        .item-details__detail_clicks {
          display: none;
        }
      }

      @media only screen and (min-width: $breakpoint-sm) {
         flex-direction: row;
      }
    }
  }
}
