@import 'variables.pcss';

.text-icon {
  margin: 0 0.313em;
}

.clear-icon {
  cursor: pointer;
  padding: 5px;
  color: #00A4C2;
}

.top-notification {
  background-color: $th-top-notification-background;
  border-bottom: 1px solid $th-news-item-borders;
  text-align: center;
  padding: 4px;

  h2 {
    font-size: 18px;

    a, a:visited {
      color: $th-text-link-color !important;
      text-decoration: $th-text-link-decoration;
      font-size: 18px;
    }

    button {
      color: $th-button-element;
    }
  }

  &.highlight {
    background-color: #312a6a !important;

    a, a:visited {
      color: white !important;
      font-weight: bold;

      span {
        color: #f6cb02 !important;
      }
    }

    a:hover {
      text-decoration: underline;
    }
  }
}

.info-box {
  text-align: left;
  padding: 20px;

  li {
    padding: 5px;
  }

  button {
    color: $th-button-element;
  }

  &-image {
    flex: 2 1 300px;
  }
}
