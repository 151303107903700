.react-autosuggest {
  &__container {
    position: relative;
  }

  &__suggestions-container {
    display: none;

    &--open {
      display: block;
      position: absolute;
      top: 40px;
      width: 240px;
      border: 1px solid #e3e3db;
      border-top: none;
      background-color: #fff;
      color: initial;
      font-size: 14px;
      z-index: 2;
    }
  }

  &__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__suggestion {
    cursor: pointer;
    padding: 10px;

    &--highlighted {
      background-color: #ddd;
    }

  }

}