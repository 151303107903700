@import 'variables.pcss';

input[type='radio']
{
  background-color: transparent;
}

.button-group {
  display: flex;
  flex-direction: column;
  width: 115px;

  .button {
    margin-top: 10px;
  }
}

.form-container {
  display: flex;
  flex-direction: row;
  margin: calc(2 * $gutter) auto;
  box-sizing: border-box;
}

.form {
  align-items: center;

  .form-group {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    width: 100%;

    label, .label {
      flex-grow: 1;
      align-items: center;
      text-align: center;
      margin-bottom: $gutter;
    }

    input, textarea, .input-container {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      flex-grow: 1;
    }

    .input-container {

      input[type='checkbox'] {
        width: auto;
      }
    }

    button {
      width: 100%;
    }

    p {
      color: $th-visited-font-color;
      font-style: italic;
      text-align: center;
      margin-bottom: $gutter;
      max-width: 100%;
    }

    .input-error, .input-success {
      margin-top: 10px;
    }

    .input-error {
      color: $error-color;
    }

    .input-success {
      color: $th-highlight-color;
    }
  }

  .form-group + .form-group {
    margin-top: $gutter;
  }
}

@media only screen and (min-width: $breakpoint-sm) {
  .form {

    .form-group {
      flex-direction: row;

      p {
        margin-bottom: 0;
      }

      label {
        width: auto;
        margin-right: $gutter;
        text-align: right;
        margin-bottom: 0;
      }

      input, textarea, .input-container {
        max-width: 50%;
        margin-right: 15%;
      }

      textarea {
        min-height: 7em;
      }

      button {
        display: flex;
        width: auto;
        min-width: 30%;
        justify-content: center;
      }

      .input-container {

        input {
          max-width: none;
        }
      }
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;

  input[type='text'], input[type='email'], input[type='password'], input[type='url'] {
    width: 100%;
    height: 40px;
  }

  button {
    /* Extra span to fix centering on Safari */

    > span {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    height: 40px;
  }

  input.has-success, textarea.has-success {
    border-color: $th-highlight-color;
  }

  input.has-error, textarea.has-error {
    border-color: $error-color;
  }

  &.has-error {

    button {
      background: $error-color;
      border-color: $error-color;
    }
  }
}
