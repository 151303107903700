@import '../../styles/variables.pcss';

.main-navigation {
  text-align: center;
  margin: 0;
  padding: 0;


  li {
    list-style: none;
    display: inline-block;
    border-bottom: 3px solid transparent;

    a {
      display: inline-block;
      height: 16px;
      padding: 17px 0 14px;
      margin: 0 10px;
    }

    &.active {
      border-bottom-color: $th-menubar-active-highlight;

      a {
        color: $th-menubar-active-highlight;
      }
    }
  }

  a, a:visited, a:active, span {
    color: $th-lightest-gray;
  }

  a:hover {
    color: $th-main-color;
  }
}
