@import '../../styles/variables.pcss';

.tv-notification {
  margin: 20px;
  padding: 20px;

  &__title {
    font-size: 26px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  &__text-section {
    font-size: 18px;
    margin-bottom: 15px;
  }

  &__image-container {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &-image {
      margin: 10px;
      max-width: 100%;
      max-height: 300px;
    }
  }

  &__link {
    margin-top: 20px;
    padding: 10px 20px;
  }
}
