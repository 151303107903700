@import '../../styles/variables.pcss';


.item {
  padding-top: 10px;
  background-color: $th-news-item-bg;
}

.item-batch {
  background: $th-website-background;
  padding-left: 5px;
}


/*
 * Item-row contains everything you see without clicking. Meaning it does not contain the
 * actions (vote, share) or batched items. Those are contained inside .item, but not .item-row
 */

.item-row {
  display: flex;
  padding-bottom: 5px;
}

.item-batch-toggle {
  flex: 0 0 20px;
  text-align: center;

  &__button {
    font-size: 10px;
    color: #CCCCCC;
    width: 100%;
  }
}

.item-text {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  word-break: break-word;
  overflow: hidden;

  .item-title {
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    flex-basis: 100%;
    padding-right: 10px;

    a {
      color: $th-news-header;
      line-height: 18px;
    }

    a:hover {
      color: $th-news-header-hover;
    }

    a:visited {
      color: $th-visited-font-color;
    }

    &__paywalled, &__login {
      width: 20px;
      height: 20px;
      float: left;
      margin-right: 5px;
    }
  }
}

.item-actions-toggle {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px 0 14px;
  cursor: pointer;
  color: $th-news-collapsible-arrow;

  &-icon {
    width: 6px;
    height: 24px;
  }
}


/*
 * Style overrides for blacklisted items on search and #tag pages
 */

.item-list .item.blacklisted {
  background-color: $th-news-blacklisted-background;

  .item-row {
    color: $th-news-blacklisted-text;

    a, a:visited,
    .item-clicks,
    .item-votes,
    .item-actions-toggle,

    .item-details__detail {
      color: $th-news-blacklisted-text;
    }
  }
}

.login-item {
  padding: 4px 20px 8px 20px;
}
